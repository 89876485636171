import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "./store";
import {
  PostApiConclusionsSignByConclusionIdApiArg,
  PostApiConclusionsSignByConclusionIdApiResponse,
  PostApiFilesUploadFileApiArg,
  PostApiFilesUploadFileApiResponse,
} from "./apiRTK";
import { setRefresh } from "../storeSlices/login";
import { router } from "../index";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).login.access;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

let currentNetworkLogs: null | Set<string> = null;

if (process.env.NODE_ENV === "development") {
  const fromLocalStorage = localStorage.getItem("networkLogs");
  console.info("init emptyApi: " + process.env.NODE_ENV);
  if (fromLocalStorage) {
    currentNetworkLogs = new Set(JSON.parse(fromLocalStorage));
  } else {
    currentNetworkLogs = new Set<string>();
  }
}

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const accessToken = (api.getState() as RootState).login.access;
  const refreshToken = (api.getState() as RootState).login.refresh;

  if (process.env.NODE_ENV === "development") {
    if (currentNetworkLogs) {
      currentNetworkLogs.add(api.endpoint);
      localStorage.setItem(
        "networkLogs",
        JSON.stringify(Array.from(currentNetworkLogs))
      );
    }
  }

  if (
    result.error &&
    result.error.status === 401 &&
    accessToken &&
    refreshToken
  ) {
    const newBody = {
      url: "/api/Authentication/RefreshToken",
      method: "POST",
      body: {
        access: accessToken,

        refresh: refreshToken,
      },
    };
    // try to get a new token
    const refreshResult = await baseQuery(newBody, api, extraOptions);
    if (refreshResult.data) {
      // store the new token
      api.dispatch(setRefresh(refreshResult.data));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      router.navigate("/logout");
    }
  }
  return result;
};

export const emptySplitApi = createApi({
  baseQuery: baseQueryWithReauth,

  endpoints: (build) => ({
    uploadFile: build.mutation<
      PostApiFilesUploadFileApiResponse,
      PostApiFilesUploadFileApiArg
    >({
      query: (queryArg) => {
        const bodyFormData = new FormData();
        bodyFormData.append("file", queryArg.body.file || "");
        return {
          url: `/api/Files/UploadFile`,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
    }),
    signConclusion: build.mutation<
      PostApiConclusionsSignByConclusionIdApiResponse,
      PostApiConclusionsSignByConclusionIdApiArg
    >({
      query: (queryArg) => {
        const bodyFormData = new FormData();
        bodyFormData.append("signFile", queryArg.body.signFile || "");

        return {
          url: `/api/Conclusions/Sign/${queryArg.conclusionId}`,
          method: "POST",
          body: bodyFormData,
          formData: true,
          params: { certificateId: queryArg.certificateId },
        };
      },
    }),
  }),
});

export const { useUploadFileMutation, useSignConclusionMutation } =
  emptySplitApi;
