import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface IInitialState {
  hasNewNotification: boolean;
}

const initialState: IInitialState = { hasNewNotification: false };

export const slice = createSlice({
  name: "notification",
  initialState,

  reducers: {
    setHasNewNotification: (state, action: PayloadAction<boolean>) => {
      state.hasNewNotification = action.payload; // Устанавливаем состояние уведомления
    },
  },
});

export const { setHasNewNotification } = slice.actions;

export const hasNewNotificationState = (state: RootState) =>
  state.notification.hasNewNotification;

const notificationReducer = slice.reducer;
export default notificationReducer;
